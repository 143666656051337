import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import HomepageFetchHelper from '../helpers/homepage-fetcher.js';
import { extractUrlParams } from '../helpers/pagination';
import { removePrecedingSlash } from '../helpers/links';
import { navigateTo } from '#app';
import {
  generateHomepagePreviewContent,
  generateMediaPreviewContent,
  generateGalleryPreviewContent,
} from '@apptegy/preview-content-setter';

import { convertLegacyPageConfigToPageBuilderConfig } from '@cms/page-builder-adapters';

export const IS_DEV = process.env.NODE_ENV === 'development';
// TODO
const IS_INTRANET = process.env.NUXT_PUBLIC_IS_INTRANET === 'true';

export const state = () => ({
  host: null,
  alias: null,
  org: null,
  secondaryOrgId: null,
  parentOrgId: null,
  links: {},
  customSectionPaths: {},
  organizations: [],
  globals: {
    favicon: {
      url: '',
    },
    info: {
      school_name: '',
    },
  },
  domain: '',
  theme: {
    containers: [],
  },
  accentStyling: false,
  accentType: 'dot',
  font: '',
  homepage: {},
  mediaPreviewContent: {},
  aboutContent: {},
  statsContent: {},
  settings: {},
  menu: [],
  customMenuItems: [],
  menuStatus: false,
  page: {},
  pathPrefix: '',
  configLoading: true,
  gallery: [],
  customSections: [],
  iosLink: '',
  androidLink: '',
  showLightbox: false,
  lightboxImages: {},
  lightboxCurrentIndex: 0,
  isThemePreview: false,
  prevFocusedElem: {},
  alertBannerConfig: {},
  alertBannerSeparate: [],
  dynamicPagesFilters: {
    events: {},
    staff: {},
    athletics: {},
    live_feed: {},
    news: {},
    dining: {},
  },
  needsRefresh: false,
  pageContentNeedsRefresh: false,
  sectionId: '',
  sectionName: '',
  eventsHasSections: null,
  diningDisclaimer: {
    fda_statement: null,
  },
  featureFlags: {},
  staticFilesConfig: {},
  authLoggedIn: false,
  fullSiteMap: null,
  mediaSubdomain: '',
  inPageSharedContent: [],
});

export const getters = {
  getAlias(state) {
    return state.alias;
  },
  getLightboxImages(state) {
    return state.lightboxImages;
  },
  getLightBoxCurrentIndex(state) {
    return state.lightboxCurrentIndex;
  },
  getShowLightbox(state) {
    return state.showLightbox;
  },
  getSecondaryOrgId(state) {
    return state.secondaryOrgId;
  },
  getParentOrgId(state) {
    return state.parentOrgId;
  },
  getGlobals(state) {
    return state.globals;
  },
  getDomain(state) {
    return state.domain;
  },
  getMenu(state) {
    return state.menu;
  },
  getTheme(state) {
    return state.theme;
  },
  getGlobalThemeSettings(state) {
    return {
      font: state.font,
      accentStyling: state.accentStyling,
      accentType: state.accentType,
      primaryColor: get(state, 'globals.info.primary_color.hex', '#fff'),
      secondaryColor: get(state, 'globals.info.secondary_color.hex', '#000'),
      linkColor: get(state, 'globals.info.links_color.hex', '#0070E0'),
    };
  },
  getAccentStyling(state) {
    return state.accentStyling;
  },
  getAccentType(state) {
    return state.accentType;
  },
  themeExists(state) {
    return !isEmpty(state.theme);
  },
  getCustomMenuItems(state) {
    return state.customMenuItems;
  },
  getCustomSections(state) {
    return state.customSections;
  },
  getPageLayout(state) {
    return state.page.layout === 'sidebar' ? 'body-sidebar' : 'body-fullwidth';
  },
  getPageConfig(state) {
    return state.page;
  },
  getLinks(state) {
    return state.links;
  },
  getCustomSectionPaths(state) {
    return state.customSectionPaths;
  },
  getLiveFeed(state) {
    return `${state.links.live_feed}&locale=${state.translation.locale}`;
  },
  getLiveFeedApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(state.customSectionPaths.live_feed)}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale, max_images: 10 } };
  },
  getNews(state) {
    return `${state.links.news}&locale=${state.translation.locale}&not_reset_video_dimensions=true`;
  },
  getNewsApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(state.customSectionPaths.news)}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale, not_reset_video_dimensions: 'true' } };
  },
  getEvents(state) {
    return `${state.links.events}&locale=${state.translation.locale}`;
  },
  getEventsApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(
      state.customSectionPaths.v4.events.main || state.customSectionPaths.events,
    )}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale } };
  },
  getEventsSectionApi: (state) => (baseURL, slug) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(
      `${state.customSectionPaths.v4.events.sections[slug]}` || state.customSectionPaths.v4.events.main,
    )}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale } };
  },
  getAthletics(state) {
    return `${state.links.athletics}&locale=${state.translation.locale}`;
  },
  getAthleticsApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(
      state.customSectionPaths.v4.athletics.main || state.customSectionPaths.athletics,
    )}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale, upcoming: true } };
  },
  getStaff(state) {
    return `${state.links.staff}&locale=${state.translation.locale}`;
  },
  getStaffApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(
      state.customSectionPaths.v4.staff.main || state.customSectionPaths.staff,
    )}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale } };
  },
  getDiningApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(state.customSectionPaths.dining)}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale } };
  },
  getCustomSectionApi: (state) => (baseURL, path) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(path)}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale } };
  },
  getDocuments(state) {
    return `${state.links.documents}&locale=${state.translation.locale}`;
  },
  getDocumentsApi: (state) => (baseURL) => {
    const fullUrl = `${baseURL}${removePrecedingSlash(state.customSectionPaths.documents)}`;
    const { url, params } = extractUrlParams(fullUrl);
    return { url, params: { ...params, locale: state.translation.locale, not_reset_video_dimensions: 'true' } };
  },
  getS3UrlForMedia: (state) => (mediaType) => {
    const locale = state.translation.locale || 'en';
    return `/content/${state.parentOrgId}/${state.secondaryOrgId}/${locale}/homepage_${mediaType}.json`;
  },
  getOrganizations(state) {
    return state.organizations;
  },
  getPathPrefix(state) {
    return state.pathPrefix;
  },
  getHeaderStyles(state) {
    return get(state.theme, 'content.header.styles', {});
  },
  getFooterStyles(state) {
    return get(state.theme, 'content.footer.styles', {});
  },
  getMenuStyles(state) {
    return get(state.theme, 'content.menu.styles', {});
  },
  getMenuStatus(state) {
    return state.menuStatus;
  },
  getHomepage(state) {
    return state.homepage;
  },
  getSettings(state) {
    return state.settings;
  },
  getAndroidLink(state) {
    return state.androidLink;
  },
  getIOSLink(state) {
    return state.iosLink;
  },
  isThemePreview(state) {
    return state.isThemePreview;
  },
  getPrevFocusedElem(state) {
    return state.prevFocusedElem;
  },
  getEventsPageFilters(state) {
    const filters = state.dynamicPagesFilters.events;
    if (!Array.isArray(filters)) return [];
    return filters;
  },
  getStaffPageFilters(state) {
    return state.dynamicPagesFilters.staff;
  },
  getAthleticsPageFilters(state) {
    return state.dynamicPagesFilters.athletics;
  },
  getLiveFeedPageFilters(state) {
    return state.dynamicPagesFilters.live_feed;
  },
  getNewsPageFilters(state) {
    return state.dynamicPagesFilters.news;
  },
  getDiningPageFilters(state) {
    return state.dynamicPagesFilters.dining;
  },
  getCustomSectionFilters:
    (state) =>
    ({ type }) =>
      state.dynamicPagesFilters[type],
  getHost(state) {
    return state.host;
  },
  getThemeHeadingColor(state) {
    return get(state, 'globals.info.cms_heading_color.hex', null);
  },
  getThemeAlertShadowOverlay(state) {
    const alertColorHex = get(state, 'globals.info.alert_lightbox_overlay.hex', '#383147');
    return `${alertColorHex}cc`;
  },
  getSectionId(state) {
    return state.sectionId;
  },
  getSectionName(state) {
    return state.sectionName;
  },
  getDiningPageDisclaimer(state) {
    return state.diningDisclaimer;
  },
  getClientWorkState(state, _getters, rootState) {
    const { logo, watermark, favicon, secondary_organization_name, fallback_images } = state.globals;
    const { newsFallbackImage, staffFallbackImage, liveFeedFallbackImage } = state.settings;
    const { id, parent_id, name, slug, in_menu, status, breadcrumbs } = state.page;
    const liveFeedDefaultFallbackImage = fallback_images?.find((item) => item.feature === 'live_feed');
    const newsDefaultFallbackImage = fallback_images?.find((item) => item.feature === 'news');
    const staffDefaultFallbackImage = fallback_images?.find((item) => item.feature === 'staff');

    return {
      host: state.host,
      alias: state.alias || state.organizations.find((org) => org.primary)?.alias,
      secondaryOrgId: state.secondaryOrgId,
      parentOrgId: state.parentOrgId,
      globals: {
        logo,
        watermark,
        favicon,
        secondary_organization_name,
        liveFeedDefaultFallbackImage,
        newsDefaultFallbackImage,
        staffDefaultFallbackImage,
      },
      links: state.links,
      settings: {
        newsFallbackImage,
        staffFallbackImage,
        liveFeedFallbackImage,
      },
      organizations: state.organizations,
      translation: { locale: rootState.translation?.locale },
      page: { id, parent_id, name, slug, in_menu, status, breadcrumbs },
      pathPrefix: state.pathPrefix,
      iosLink: state.iosLink,
      androidLink: state.androidLink,
    };
  },
  getFullSiteMap(state) {
    return state.fullSiteMap;
  },
};

export const mutations = {
  openLightbox(state) {
    state.showLightbox = true;
  },
  closeLightbox(state) {
    state.showLightbox = false;
  },
  setLightboxImages(state, payload) {
    state.lightboxImages = payload;
  },
  setLightBoxCurrentIndex(state, payload) {
    state.lightboxCurrentIndex = payload;
  },
  setConfigLoading(state, payload) {
    state.configLoading = payload;
  },
  setGallery(state, payload) {
    state.gallery = payload;
  },
  setSecondaryOrgId(state, payload) {
    // state.org is required for CE custom code but deprecated for dev use
    state.org = payload;
    state.secondaryOrgId = payload;
  },
  setParentOrgId(state, payload) {
    state.parentOrgId = payload;
  },
  setLinks(state, payload) {
    state.links = payload;
  },
  setCustomSectionPaths(state, payload) {
    state.customSectionPaths = payload;
  },
  setOrganizations(state, payload) {
    state.organizations = payload;
  },
  removePathPrefixesFromOrgs(state) {
    state.organizations = state.organizations.map((org) => {
      org.path_prefix = '';
      return org;
    });
  },
  setPathPrefix(state, payload) {
    state.pathPrefix = payload;
  },
  setGlobals(state, payload) {
    state.globals = payload;
  },
  setDomain(state, payload) {
    state.domain = payload;
  },
  setTheme(state, payload) {
    state.theme = payload;
  },
  setNeedsRefresh(state, payload) {
    state.needsRefresh = payload;
  },
  setPageContentNeedsRefresh(state, payload) {
    state.pageContentNeedsRefresh = payload;
  },
  setAccentStyling(state, payload) {
    state.accentStyling = payload;
  },
  setAccentType(state, payload) {
    state.accentType = payload;
  },
  setHomepage(state, payload) {
    state.homepage = payload;
  },
  setMediaPreviewContent(state, payload) {
    state.mediaPreviewContent = payload;
  },
  setAboutPreviewContent(state, payload) {
    state.aboutContent = payload;
  },
  setStatsPreviewContent(state, payload) {
    state.statsContent = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  },
  setFont(state, payload) {
    if (payload === 'Univers Next' || payload === 'Work Sans') {
      state.font = 'Work Sans';
    } else {
      state.font = 'Inter';
    }
  },
  setMenu(state, payload) {
    state.menu = payload;
  },
  setCustomMenuItems(state, payload) {
    state.customMenuItems = payload;
  },
  setCustomSections(state, payload) {
    state.customSections = payload;
  },
  toggleMenu(state, status) {
    state.menuStatus = status;
  },
  closeMenu(state) {
    state.menuStatus = false;
  },
  setPageConfig(state, payload) {
    state.page = payload;
  },
  setPageSharedContent(state, payload) {
    state.inPageSharedContent = payload;
  },
  setIOSLink(state, payload) {
    state.iosLink = payload;
  },
  setAndroidLink(state, payload) {
    state.androidLink = payload;
  },
  setIsThemePreview(state, payload) {
    state.isThemePreview = payload;
  },
  setUpPreviewFooter(state, footerOptions) {
    state.homepage.footer.logo.image_alignment = 'left';
    state.homepage.footer.columns[2].header = 'Quick Links';
    state.homepage.footer.logo.url = state.globals.logo.url || '';
    state.homepage.footer.organization_info = {
      zip: '71901',
      city: 'Hot Springs',
      name: state.globals.info.school_name || '',
      state: 'AR',
      address: '200 Whittington Avenue',
    };
    if (footerOptions.version === 'F2') {
      state.homepage.footer.logo.image_alignment = 'center';
      state.homepage.footer.columns[2].header = 'Stay Connected';
    }
  },
  setPrevFocusedElem(state, payload) {
    state.prevFocusedElem = payload;
  },
  setAlertBannerConfig(state, payload) {
    state.alertBannerConfig = payload;
  },
  setAlertBannerSeparate(state, payload) {
    state.alertBannerSeparate = payload;
  },
  setUpPreviewGallery(state, galleryOptions) {
    const galleryInfoByOrg = generateGalleryPreviewContent(state.globals.info.organization_type);
    state.homepage.gallery =
      galleryOptions.use_video && galleryOptions.version === 'G2'
        ? galleryInfoByOrg.videoContent
        : galleryInfoByOrg.staticImageContent;
  },
  setDynamicPageFilters(state, { type, filters }) {
    state.dynamicPagesFilters[type] = filters;
  },
  setSectionId(state, id) {
    state.sectionId = id;
  },
  setSectionName(state, name) {
    state.sectionName = name;
  },
  setEventsHasSections(state, payload) {
    state.eventsHasSections = payload;
  },
  setHost(state, host) {
    state.host = host;
  },
  setAlias(state, payload) {
    state.alias = payload;
  },
  setDiningDisclaimer(state, disclaimer) {
    state.diningDisclaimer = disclaimer;
  },
  setFeatureFlags(state, payload) {
    state.featureFlags = payload;
  },
  setStaticFilesConfig(state, payload) {
    state.staticFilesConfig = payload;
  },
  setAuthUserLoaded(state, payload) {
    state.authLoggedIn = payload;
  },
  setFullSiteMap(state, payload) {
    state.fullSiteMap = payload;
  },
  setMediaSubdomain(state, payload) {
    state.mediaSubdomain = payload;
  },
};

function setConfig(commit, configData) {
  commit('setTheme', configData.active_theme.content);
  commit('setAccentStyling', configData.active_theme.accent_styling);
  commit('setAccentType', configData.active_theme.accent_type);
  commit('setFont', configData.active_theme.font);
  commit('setHomepage', configData.homepage);
  commit('setSettings', configData.settings);
  commit('setGlobals', configData);
  commit('translation/setComponentTranslations', configData.component_translations);
}

function setDataForThemePreview(commit, siteStructureData) {
  commit('setIsThemePreview', true);
  commit('setIOSLink', 'https://www.apple.com/ios/app-store');
  commit('setAndroidLink', 'https://play.google.com/store');
  commit('removePathPrefixesFromOrgs');

  // CONFIG DATA
  const { config: configData, alert_banner: alertBanner, alert_banners: alertBannerSeparate } = siteStructureData;
  commit('setGlobals', configData);
  commit('translation/setComponentTranslations', configData.component_translations);
  commit('setAccentType', configData.active_theme.accent_type);
  commit('setSettings', {
    ...configData.settings,
    liveFeedFallbackImage: {
      url:
        configData.info.organization_type === 'Municipality'
          ? 'https://d2kzwze52p8717.cloudfront.net/city-streets.jpg'
          : 'https://d2kzwze52p8717.cloudfront.net/gallery_01.png',
      alt: 'live feed default image',
    },
  });

  // HOMEPAGE PREVIEW CONTENT
  const homepagePreviewContent = generateHomepagePreviewContent(configData.info.organization_type);
  commit('setAboutPreviewContent', homepagePreviewContent.aboutPreviewContent);
  commit('setStatsPreviewContent', homepagePreviewContent.statsPreviewContent);
  commit('setMenu', homepagePreviewContent.siteMenuDefaults);
  commit('setHomepage', {
    ...homepagePreviewContent.editableHomepageComponents,
    header: { links: homepagePreviewContent.utilityLinkDefaults },
    schools_menu: homepagePreviewContent.orgMenuDefaults,
  });
  commit('setOrganizations', homepagePreviewContent.orgDefaults);

  // MEDIA PREVIEW CONTENT
  const mediaPreviewContent = generateMediaPreviewContent(configData.info.organization_type);
  commit('setMediaPreviewContent', mediaPreviewContent);

  // ALERT BANNER
  commit('setAlertBannerConfig', {
    ...alertBanner,
    content: homepagePreviewContent.alertDefaults.data,
  });

  // ALERT BANNER SEPARATE
  commit('setAlertBannerSeparate', {
    data: [...alertBannerSeparate, ...homepagePreviewContent.alertSeparateDefaults],
    preview: true,
  });

  commit('setConfigLoading', false);
}

function setDomainData(commit, domainData) {
  commit('setPathPrefix', domainData.path_prefix);
  commit('setLinks', domainData.links);
  commit('setCustomSectionPaths', domainData.paths);
  commit('setIOSLink', domainData.ios_app_link);
  commit('setAndroidLink', domainData.android_app_link);
  commit('setOrganizations', domainData.organizations);
  commit('translation/setLanguages', domainData.languages);
  commit('setMediaSubdomain', domainData.media_subdomain);
}

function setTranslationData(commit, cookies) {
  commit('translation/setLanguage', cookies.langCode);
  commit('translation/setLanguageText', cookies.language);
}

function setRequestData(commit, requestData) {
  commit('setAlias', requestData.alias);
  commit('setHost', requestData.host);
  commit('setDomain', requestData.domain);
  if (requestData.locale) {
    setTranslationData(commit, requestData.cookies);
  }
}

export const actions = {
  async nuxtServerInit({ commit, state }, context) {
    const nuxtServerInitHelper = new HomepageFetchHelper(context.event);

    // DATA FROM REQUEST URL
    const requestData = nuxtServerInitHelper.extractDataFromInitialRequest(IS_DEV);
    await setRequestData(commit, requestData);

    let orgDetails;
    let siteMetadata;
    let siteStructureData;

    if (IS_INTRANET && !context.authCookie) {
      return;
    }

    try {
      try {
        orgDetails = await nuxtServerInitHelper.fetchOrgDetails(requestData, state.alias || '');
        siteMetadata = await nuxtServerInitHelper.fetchSiteMetadata(orgDetails);
        siteStructureData = await nuxtServerInitHelper.fetchSiteStructure(
          requestData,
          orgDetails,
          siteMetadata,
          IS_INTRANET,
          context.authCookie,
        );
        commit('setStaticFilesConfig', siteMetadata.files_config);
        commit('setFeatureFlags', siteMetadata.feature_flags);
      } catch (error) {
        console.error(error);
        const legacyData = await nuxtServerInitHelper.fetchLegacyAPIs(requestData);
        orgDetails = legacyData.orgDetails;
        siteStructureData = legacyData.siteStructureData;
        console.error('Static files not present, falling back to legacy APIs', error);
        commit('setFeatureFlags', legacyData.featureFlagsData);
      }

      commit('setParentOrgId', orgDetails.parent_organization_id);
      commit('setSecondaryOrgId', orgDetails.secondary_organization_id);
    } catch (error) {
      throw new Error(error);
    }

    const pageHasRedirect = await nuxtServerInitHelper.handleRedirects(siteStructureData.redirects, context.nuxt);
    if (pageHasRedirect) {
      return;
    }

    const newsRedirectPath = HomepageFetchHelper.getArticleRedirectUrl(context.event.req);
    if (newsRedirectPath) {
      context.nuxt.runWithContext(async () => {
        await navigateTo(newsRedirectPath, {
          external: true,
        });
      });
      return;
    }

    commit('setCustomMenuItems', siteStructureData.custom_menu_items);
    await setDomainData(commit, siteStructureData.domain);

    if (nuxtServerInitHelper.isThemePreview) {
      await setDataForThemePreview(commit, siteStructureData);
      return;
    }

    commit('setMenu', siteStructureData.sitemap);
    commit('setAlertBannerConfig', siteStructureData.alert_banner);
    commit('setAlertBannerSeparate', siteStructureData.alert_banners);
    commit('setCustomSections', siteStructureData.custom_sections);

    await setConfig(commit, siteStructureData.config);
    commit('setConfigLoading', false);
  },
  async getSearchResults({ state }, { term, writableApi, searchByCurrentOrg, selectedFilters, token }) {
    try {
      const searchAPI = `${writableApi}api/v4/o/${state.secondaryOrgId}/cms/search?`;
      const headers = token ? { Authorization: `${token}` } : {};
      const response = await axios.get(`${searchAPI}`, {
        headers: {
          ...headers,
        },
        params: {
          q: term,
          ...(searchByCurrentOrg ? { secondary_organizations: [state.secondaryOrgId] } : {}),
          ...(selectedFilters.length > 0 ? { query_indices: selectedFilters } : {}),
        },
      });
      return response;
    } catch (e) {
      console.error(e);
      return { data: { results: [] } };
    }
  },
  async setPageData({ state, commit }, payload) {
    if (state.menuStatus) {
      commit('toggleMenu');
    }
    // If FF enabled
    const pageRendererEnabled =
      !!state.featureFlags.cms_page_renderer_package_enabled && !!payload?.flags?.new_renderer_enabled;
    let pageConfig = payload;

    if (pageRendererEnabled) {
      const convertedContent = convertLegacyPageConfigToPageBuilderConfig(payload.content);
      pageConfig = { ...payload, content: convertedContent };
      commit('setPageSharedContent', payload.shared_layouts);
    }
    commit('setPageConfig', pageConfig);
  },

  setCustomSectionData({ commit, getters }, { type, fallbackSectionName }) {
    const customSection = getters.getCustomSections.find((s) => s.type === type);
    const sectionName = customSection ? customSection.name : fallbackSectionName;
    commit('setSectionId', customSection ? customSection.id : -1);
    commit('setSectionName', sectionName);
  },
};
